@import "../node_modules/mouse-follower/src/scss/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova&display=swap");

* {
	box-sizing: border-box;
	position: relative;
}

::selection {
	background: #000;
	color: #fff;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	width: 100%;
	height: 100vh;
}

.coming-soon {
	display: flex;
	justify-content: space-between;
	height: 100vh;

	.image {
		width: 40%;
		height: 100%;
		filter: grayscale(100%) blur(1px);
		opacity: 0;
		transform: scale(1.125);
		transform-origin: center right;
		object-fit: cover;

		&.active {
			opacity: 1;
			transform: scale(1.025);
			filter: grayscale(100%) blur(0px);
			transition: all 1s ease-in-out;
		}
	}

	.content {
		width: 60%;
		height: 100%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 5vw;

		opacity: 0;
		// transform: scale(1.125);
		transform-origin: center left;

		.name {
			padding: 1px;
			width: 100%;
			text-align: left;
			font-family: "Times", serif;
			font-size: clamp(1rem, 20vh, 200px);

			@media (max-width: 970px) {
				font-size: clamp(1rem, 15vh, 200px);
			}

			p {
				margin: 0;
				display: block;
				line-height: 1em;
				color: black;

				overflow: hidden;
				padding: 0.05em 0;

				span {
					display: inline-block;
					position: relative;
					transform: rotate(45deg) translate(-25%, 100%);
					transform-origin: bottom left;
					transition: transform 0.5s
							cubic-bezier(0.075, 0.82, 0.165, 1),
						color 0.5s ease-in-out;

					&.active {
						transform: rotate(0deg) translate(0%, 0%);
					}
				}
			}

			&:after {
				content: "";
				display: block;
				width: 0%;
				height: 8px;
				background-color: black;
			}
		}

		.information {
			width: 100%;
			list-style-type: none;
			padding: 0;
			margin: 24px 0 0 0;
			display: flex;
			justify-content: flex-end;
			font-family: "Ibarra Real Nova", serif;
			font-weight: bold;
			font-size: 20px;
			transform: translate(0%, 100%);
			opacity: 0;
			transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;

			&:hover a {
				color: #777;
			}

			a {
				color: #aaa;
				text-decoration: none;
				transition: all 0.3s ease;

				&:hover {
					color: black;
				}
			}

			li:not(:last-child) {
				&:after {
					content: "-";
					margin-right: 16px;
					margin-left: 16px;
					color: #aaa;
				}
			}

			@media (max-width: 1250px) {
				flex-direction: column;
				text-align: right;
				gap: 4px;

				li:after {
					display: none;
				}
			}

			@media (max-width: 768px) {
				font-size: 16px;
			}
		}

		&.active {
			opacity: 1;
			// transform: scale(1.025);
			transition: all 1s 0.5s ease-in-out;

			.name:after {
				width: 100%;
				transition: all 1.5s ease 1.25s;
			}

			.information {
				transform: translate(0%, 0%);
				opacity: 1;
			}
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;

		.image {
			width: 100%;
			height: 40%;
			transform-origin: center bottom;
		}

		.content {
			width: 100%;
			height: 60%;
			flex-shrink: 0;
			transform-origin: center top;
			display: block;
			padding: 36px 7.5vw;

			.name {
				font-size: 10vh;

				&:after {
					height: 4px;
				}
			}

			.information {
				font-size: 18px;
			}
		}
	}
}

.mf-cursor:before {
	width: 48px;
	height: 48px;
	top: -24px;
	left: -24px;
	transform: scale(1);
	background: none;
	border: 2px solid #ccc;
}
